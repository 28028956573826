<script lang="ts">
  import { onMount, type ComponentEvents } from "svelte";
  import { writable } from "svelte/store";
  import { fade } from "svelte/transition";
  import { inertia, page, router } from "@inertiajs/svelte";
  import hotkeys from "hotkeys-js";
  import {
    autoPlacement,
    offset,
    type VirtualElement,
  } from "svelte-floating-ui/dom";
  import { createFloatingActions } from "svelte-floating-ui";

  import API from "$api";
  import { type PaginationData } from "$lib/backend";
  import { portal } from "$lib/actions";
  import { currentUser, updateUserPref } from "$stores/user";
  import { clear, selectAll, selection } from "$stores/selection";

  import AssetInfoPanel from "$views/assets/info.svelte";

  import MasonryBrick from "$components/views/MasonryBrick.svelte";
  import MasonryView from "$components/views/MasonryView.svelte";

  import EmptyState from "$components/EmptyState.svelte";
  import ResponsiveAssetImage from "$components/ResponsiveAssetImage.svelte";
  import Selection from "$components/Selection.svelte";
  import SelectionItem from "$components/SelectionItem.svelte";
  import Lightbox from "$components/Lightbox.svelte";
  import MasonryBrickAction from "$components/views/MasonryBrickAction.svelte";
  import Input from "$components/forms/Input.svelte";
  import Spinner from "$components/Spinner.svelte";

  import * as Tooltip from "$components/ui/tooltip";
  import * as Pagination from "$components/ui/pagination";
  import { Slider } from "$components/ui/slider";

  import EnlargeIcon from "~icons/ph/arrows-out-simple";
  import InfoIcon from "~icons/ph/info";
  import CheckIcon from "~icons/ph/check-bold";

  export let assets: Schema.Asset[];
  export let pagination: PaginationData;
  export let selectionComponent: Selection | undefined = undefined;

  const numberFmt = new Intl.NumberFormat(["en-BE", "fr-BE"]);
  const [floatingRef, floatingContent] = createFloatingActions({
    strategy: "absolute",
    placement: "right-start",
    middleware: [autoPlacement({ alignment: "start" }), offset(10)],
    autoUpdate: {
      ancestorScroll: false,
    },
  });
  let floatingRefElem: HTMLElement;
  const virtualElem = writable<VirtualElement>({
    getBoundingClientRect: () => {
      return (
        floatingRefElem?.getBoundingClientRect() ?? {
          x: 0,
          y: 0,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: 0,
          height: 0,
        }
      );
    },
  });
  floatingRef(virtualElem);

  let loading: boolean = false;
  let lightbox: Lightbox;
  let lightboxOpen: boolean;

  // This variable will only be set when the user selected a single asset.
  let selectedAsset: Schema.Asset | undefined = undefined;

  let assetInfoPanelOpen: boolean = false;
  let selectedAssetImage: HTMLImageElement | null = null;
  let assetInfoPanel: HTMLDivElement;

  // This variable is used when the user is about to preview an asset or when
  // they select a single asset.
  let preloadAsset: Schema.Asset | undefined = undefined;

  let dragPill: HTMLDivElement;
  let pointerX: number = 0;
  let pointerY: number = 0;

  let brickHeight: number = 300;
  $: brickHeight = (document.documentElement.clientHeight * thumbScale) / 100;

  // Zoom level of the asset thumbnails in percent.
  $: thumbScale = $currentUser?.preferences?.masonry_zoom_level ?? 25;

  // If the user requested a page number that is too high, or if they changed
  // their assets per page setting and it loewered the page number, redirect
  // to the last page.
  $: currentUrl = $page?.url && new URL($page.url, window.location.href);
  $: if (pagination && currentUrl) {
    // Get requested page number from the URL.
    const pageStr = currentUrl.searchParams.get("page");
    if (pageStr) {
      const page = parseInt(pageStr);
      if (page && page > pagination.last) router.visit(pagination.last_url);
    }
  }

  $: if (selectionComponent?.enableOCD && selectionComponent?.disableOCD) {
    lightboxOpen
      ? selectionComponent.disableOCD()
      : selectionComponent.enableOCD();
  }

  onMount(() => {
    // Remove any previously defined hot keys to refresh any stale references
    // inside them, such as `lightbox` here.
    hotkeys.deleteScope("assets_masonry_view");

    hotkeys("space", { scope: "assets_masonry_view" }, function () {
      if ($selection.size === 1) {
        lightbox.open();
        return false;
      }
    });

    hotkeys(
      "ctrl+i, command+i",
      { scope: "assets_masonry_view" },
      function (event) {
        if (!selectedAsset) return true;

        if (assetInfoPanelOpen) hideAssetInfo();
        else showAssetInfo(selectedAsset.id);

        event.preventDefault();
        return false; // note: this overrides a browser hotkey
      },
    );

    hotkeys("ctrl+a, command+a", function (event) {
      selectAll();
      event.preventDefault();
      return false; // note: this overrides a browser hotkey
    });

    hotkeys("escape", function () {
      clear();
    });

    // Listen to Inertia router events to show loading animation when reloading
    // the assets.
    const removeListenersCallbacks = [
      router.on("start", (event) => {
        if (!$page?.url) return;

        const currentUrl = new URL($page.url, window.location.href);
        loading = event.detail.visit.url.pathname === currentUrl.pathname;
      }),
      router.on("finish", () => (loading = false)),
    ];

    return () => {
      // Remove listeners on Inertia router when changing page.
      removeListenersCallbacks.forEach((cb) => cb());
    };
  });

  // `asset` is the first argument thanks to `bind`.
  function onSelectionItemChange(
    asset: Schema.Asset,
    { detail }: ComponentEvents<SelectionItem>["change"],
  ) {
    if ($selection.size === 1 && $selection.has(detail.id)) {
      // Single-selection

      // Prepare lightbox on single asset selection.
      prepareLightbox(asset);
      // Get hot key focus when user clicks on a selection item.
      hotkeys.setScope("assets_masonry_view");

      selectedAsset = asset;
    } else {
      selectedAsset = undefined;
    }
  }

  function showAssetInfo(assetId: number) {
    selectedAssetImage = document.querySelector(
      `img[data-asset-id="${assetId}"]`,
    ) as HTMLImageElement | null;

    if (!selectedAssetImage) return;

    floatingRefElem = selectedAssetImage;
    assetInfoPanelOpen = true;

    // Disable selection changes while Asset Info Panel is open.
    selectionComponent?.disableOCD();

    // Watch mouse clicks to close Asset Info Panel when user clicks away.
    document.body.addEventListener("click", onClickWhileAssetInfoOpen);
  }

  function hideAssetInfo() {
    assetInfoPanelOpen = false;

    // Re-enable selection changes when Asset Info Panel is closed.
    selectionComponent?.enableOCD();

    document.body.removeEventListener("click", onClickWhileAssetInfoOpen);
  }

  // Global mouse click listener when Asset Info Panel is open. If user clicks
  // outside the Asset Info Panel or the Asset image, the Asset Info Panel
  // is closed.
  function onClickWhileAssetInfoOpen(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (
      (selectedAssetImage && selectedAssetImage.contains(target)) ||
      assetInfoPanel.contains(target)
    ) {
      return;
    }

    hideAssetInfo();
  }

  function prepareLightbox(asset: Schema.Asset) {
    const thumbnail = document.querySelector(
      `img[data-asset-id="${asset.id}"]`,
    );

    if (thumbnail) lightbox.$set({ originElement: thumbnail });
    preloadAsset = asset;
  }

  function onThumbScaleChange(newValue: number[]) {
    thumbScale = newValue[0];
  }

  function onThumbScaleCommit() {
    if ($currentUser?.preferences?.masonry_zoom_level !== thumbScale) {
      updateUserPref("masonry_zoom_level", thumbScale);
    }
  }

  // This function updates the assets per page user setting, then it reloads the
  // page to show the new count of assets.
  function onAssetsPerPageCommit({
    detail: newCountStr,
  }: ComponentEvents<Input>["enter"] | ComponentEvents<Input>["blur"]) {
    if (!newCountStr) return;

    const newCount = parseInt(newCountStr);

    if (
      newCount &&
      newCount !== $currentUser?.preferences?.masonry_assets_per_page
    ) {
      updateUserPref("masonry_assets_per_page", newCount).then(() =>
        router.reload(),
      );
    }
  }

  function onAssetDragStart(event: DragEvent) {
    const selectedAssets = assets.filter((_, idx) => $selection.has(idx));

    pointerX = event.pageX;
    pointerY = event.pageY;
    dragPill.style.display = "block";

    // The `false` param and `event.preventDefault()` in the callback are
    // required in order to fire the `dragend` event immediately without delay.
    document.addEventListener("dragover", trackPointer, false);

    // Disable default ghost image.
    event.dataTransfer?.setDragImage(new Image(), 0, 0);

    event.dataTransfer?.setData(
      "text/plain",
      JSON.stringify({ assets: selectedAssets }),
    );
  }

  function onAssetDragEnd() {
    document.removeEventListener("dragover", trackPointer);
    dragPill.style.display = "none";
  }

  function trackPointer(event: MouseEvent) {
    event.preventDefault();

    pointerX = event.pageX;
    pointerY = event.pageY;
  }

  function onAssetDoubleClick() {
    lightbox.open();
  }
</script>

<section
  class="grid grid-rows-[minmax(0,auto)_minmax(0,1fr)_auto] w-full h-full"
>
  {#if $$slots.header}
    <header
      class="flex items-center gap-4 h-24 px-6 bg-zinc-100 border-b border-zinc-300"
    >
      <slot name="header" />
    </header>
  {/if}

  <main
    class="relative h-full overflow-y-auto overflow-x-hidden"
    class:row-span-2={!$$slots.header}
  >
    {#if assets.length}
      <Selection
        class="max-w-full"
        ariaLabel="Select assets"
        bind:this={selectionComponent}
      >
        <MasonryView class="p-4">
          {#if assetInfoPanelOpen}
            <div
              class="z-30 fixed top-0 left-0 w-screen h-screen bg-black/80"
              on:click={() => hideAssetInfo()}
              transition:fade={{ duration: 150 }}
            ></div>
          {/if}

          {#each assets as asset, idx (asset.id)}
            <MasonryBrick
              class={selectedAsset?.id === asset.id && assetInfoPanelOpen
                ? "z-30 group"
                : "group"}
              aspectRatio={asset.file.metadata.aspect_ratio ?? 1}
              height={brickHeight}
              margin="m-2 mb-6"
              let:width
            >
              <slot name="addons" {asset} />

              <SelectionItem
                id={idx}
                draggable
                class="w-full h-full"
                let:selected
                on:change={onSelectionItemChange.bind(null, asset)}
                on:dblclick={onAssetDoubleClick}
                on:dragstart={onAssetDragStart}
                on:dragend={onAssetDragEnd}
              >
                {#if selected}
                  <div
                    class="z-[1] absolute top-2 right-2 flex items-center p-2 bg-blue-600 rounded-full text-secondary"
                  >
                    <CheckIcon class="text-xl" />
                  </div>
                {/if}

                <ResponsiveAssetImage
                  class="w-full h-full select-none"
                  {asset}
                  {width}
                  {selected}
                />

                <div
                  class="block w-fit max-w-[80%] mt-1.5 mx-auto px-1 rounded-md text-xs truncate text-center select-none"
                  class:bg-blue-500={selected}
                  class:bg-zinc-350={!selected}
                  class:text-white={selected}
                >
                  {asset.name}
                </div>

                <div
                  class="absolute bottom-0 right-1 items-stretch flex gap-1"
                  class:visible={selected && $selection.size === 1}
                  class:invisible={!selected || $selection.size > 1}
                >
                  <slot name="actions" {asset} />

                  <Tooltip.Root openDelay={150}>
                    <Tooltip.Trigger asChild let:builder>
                      <button
                        class="w-auto h-full aspect-square p-0.5 bg-black/50 rounded"
                        use:builder.action
                        {...builder}
                        on:click|stopPropagation={() => showAssetInfo(asset.id)}
                      >
                        <InfoIcon class="text-sm text-white" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Content side="top">
                      <p>Info</p>
                    </Tooltip.Content>
                  </Tooltip.Root>

                  <MasonryBrickAction
                    tooltip="Preview"
                    on:click={() => lightbox.open()}
                    on:mouseenter={() => prepareLightbox(asset)}
                  >
                    <EnlargeIcon class="text-sm text-white" />
                  </MasonryBrickAction>
                </div>
              </SelectionItem>
            </MasonryBrick>
          {:else}
            <slot name="empty-state" />
          {/each}
        </MasonryView>
      </Selection>

      <div
        class="z-40 p-4 bg-white border border-zinc-300 rounded-lg shadow-lg"
        class:hidden={!assetInfoPanelOpen}
        style:position="absolute"
        style:width="max-content"
        style:top="0"
        style:left="0"
        use:floatingContent
        bind:this={assetInfoPanel}
      >
        {#if selectedAsset}
          <AssetInfoPanel
            assetId={selectedAsset.id}
            on:destroy={hideAssetInfo}
          />
        {/if}
      </div>

      <Lightbox
        contentWidth={preloadAsset?.file.metadata.width ?? 1}
        contentHeight={preloadAsset?.file.metadata.height ?? 1}
        title={preloadAsset?.name ?? ""}
        hasChrome
        hasBackdrop
        let:width
        bind:isOpen={lightboxOpen}
        bind:this={lightbox}
      >
        <div class="hidden items-center gap-4" slot="actions">
          {#key preloadAsset}
            {#if preloadAsset}
              <a
                href={API.assets.show.path({ id: preloadAsset.id })}
                class="py-0.5 px-1 hover:bg-zinc-200 border rounded-md text-xs"
                use:inertia
              >
                Open
              </a>
            {/if}
          {/key}
        </div>

        {#key preloadAsset}
          {#if preloadAsset}
            <ResponsiveAssetImage
              class="max-w-full max-h-full object-contain"
              asset={preloadAsset}
              derivativeClass="previews"
              {width}
            />
          {/if}
        {/key}
      </Lightbox>
    {:else}
      <EmptyState title="Oops.">There are no assets to display here.</EmptyState
      >
    {/if}
  </main>

  {#if pagination && pagination.count > 0}
    <footer
      class="relative flex items-center h-14 px-4 bg-gradient-to-t from-zinc-200 to-zinc-150 border-t border-zinc-400 shadow-footer text-sm"
    >
      {#if $selection.size > 0}
        <div
          class="absolute top-0 -translate-y-full -my-4 py-1 px-2 bg-zinc-300 bg-opacity-80 rounded-md text-sm text-zinc-800"
        >
          {$selection.size}
          {$selection.size > 1 ? "assets" : "asset"} selected
        </div>
      {/if}

      <div class="flex items-center gap-4 w-1/3">
        <span>
          Showing {numberFmt.format(pagination.offset + 1)} - {numberFmt.format(
            pagination.offset + pagination.in,
          )} of
          {numberFmt.format(pagination.count)}
          assets
        </span>
      </div>

      <Pagination.Root
        page={pagination.page}
        count={pagination.count}
        perPage={pagination.limit}
        class="w-1/3"
        let:pages
        let:currentPage
      >
        <Pagination.Content>
          <Pagination.Item>
            <Pagination.PrevButton />
          </Pagination.Item>
          {#each pages as page (page.key)}
            {#if page.type === "ellipsis"}
              <Pagination.Item>
                <Pagination.Ellipsis />
              </Pagination.Item>
            {:else}
              <Pagination.Item>
                <Pagination.Link {page} isActive={currentPage == page.value}>
                  {page.value}
                </Pagination.Link>
              </Pagination.Item>
            {/if}
          {/each}
          <Pagination.Item>
            <Pagination.NextButton />
          </Pagination.Item>
        </Pagination.Content>
      </Pagination.Root>

      <div class="flex items-center justify-end gap-8 w-1/3">
        <div class="flex items-center gap-1">
          <span>Show</span>
          <Input
            name="assets_per_page"
            wrapperClass="w-12 p-1 bg-zinc-100 border border-zinc-300 rounded-md"
            class="text-center"
            value={$currentUser?.preferences?.masonry_assets_per_page.toString() ??
              pagination.limit.toString()}
            on:enter={onAssetsPerPageCommit}
            on:blur={onAssetsPerPageCommit}
          />
          <span>assets per page</span>
        </div>

        <Slider
          value={[thumbScale]}
          min={10}
          max={100}
          step={5}
          class="w-1/3"
          onValueChange={onThumbScaleChange}
          on:valueCommitted={onThumbScaleCommit}
        />
      </div>
    </footer>
  {/if}
</section>

{#if loading}
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center w-32 aspect-square bg-primary/80 rounded-xl"
    transition:fade={{ duration: 250 }}
  >
    <Spinner size="xl" color="white" />
    <span class="pt-2 text-zinc-50">Loading</span>
  </div>
{/if}

<div
  class="hidden z-[90] absolute mt-4 ml-2 py-0.5 px-2 bg-red-600 rounded text-white text-xs"
  style:top={`${pointerY}px`}
  style:left={`${pointerX}px`}
  bind:this={dragPill}
  use:portal
>
  {$selection.size}
  {$selection.size === 1 ? "asset" : "assets"}
</div>

<style>
  .shadow-footer {
    box-shadow:
      0 -1px 2px 0 rgb(0 0 0 / 0.1),
      inset 0 1px 1px 0 rgba(255, 255, 255, 1);
  }
</style>
