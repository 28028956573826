// JsFromRoutes CacheKey 8232fc5adbc7d6cf6b0dfb3c7c9add70
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/forgot_password'),
  create: /* #__PURE__ */ definePathHelper('post', '/forgot_password'),
  edit: /* #__PURE__ */ definePathHelper('get', '/user_password/:token'),
  update: /* #__PURE__ */ definePathHelper('put', '/user_password/:token'),
}
