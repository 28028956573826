<script lang="ts">
  import { fade } from "svelte/transition";

  import API from "$api";
  import { cn } from "$lib/utils";

  import Spinner from "$components/Spinner.svelte";

  import ErrorIcon from "~icons/ph/file-x";
  import WaitingIcon from "~icons/ph/hourglass-high-fill";

  export let asset: Schema.Asset;
  export let width: number | string;
  export let element: HTMLImageElement | undefined = undefined;
  export let selected: boolean = false;
  export let derivativeClass: "thumbnails" | "previews" = "thumbnails";
  export let loadedClass: string | undefined = undefined;
  export let unloadedClass: string | undefined = undefined;

  let smallestWidth: number | undefined = undefined;
  let smallestKey: string | undefined = undefined;
  let urls: string[] = [];
  let srcset: string = "";

  $: if (derivativeClass in asset) {
    const derivatives = asset[derivativeClass];
    const acc: string[] = [];

    for (const size in derivatives) {
      const url = API.assetDerivatives.show.path({
        asset_id: asset.id,
        derivative_class: derivativeClass,
        key: size,
      });

      if (smallestWidth) {
        if (smallestWidth > derivatives[size].metadata.width) {
          smallestWidth = derivatives[size].metadata.width;
          smallestKey = size;
        }
      } else {
        smallestWidth = derivatives[size].metadata.width;
        smallestKey = size;
      }

      urls.push(url);
      acc.push(`${url} ${derivatives[size].metadata.width}w`);
    }

    srcset = acc.join(",");
  }

  loadedClass = cn("w-full", loadedClass);
  unloadedClass = cn(
    "flex items-center justify-center w-full h-full bg-zinc-300 border border-zinc-400/50",
    unloadedClass,
  );
</script>

<div class={smallestKey ? loadedClass : unloadedClass} class:selected>
  {#if urls.length && asset.state === "available"}
    <img
      class={$$props.class ?? ""}
      src={smallestKey
        ? API.assetDerivatives.show.path({
            asset_id: asset.id,
            derivativeClass: derivativeClass,
            key: smallestKey,
          })
        : urls[0]}
      {srcset}
      sizes={Number.isNaN(width) ? width.toString() : `${width}px`}
      alt={asset.name}
      data-asset-id={asset.id}
      in:fade={{ duration: 500 }}
      bind:this={element}
    />
  {:else if asset.state === "error"}
    <div class="text-center">
      <ErrorIcon class="text-4xl text-zinc-700" />
      <span class="block pt-2 text-zinc-500 font-medium text-sm">Failed</span>
    </div>
  {:else if asset.state === "processing"}
    <div class="flex flex-col items-center">
      <Spinner size="xl" />
      <span class="block pt-2 text-zinc-500 font-medium text-sm">
        Processing
      </span>
    </div>
  {:else}
    <div class="text-center">
      <WaitingIcon class="text-4xl text-zinc-700" />
      <span class="block pt-2 text-zinc-500 font-medium text-sm">Waiting</span>
    </div>
  {/if}
</div>

<style>
  .selected {
    @apply ring-4 ring-blue-500;
  }
</style>
