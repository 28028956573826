<script context="module" lang="ts">
  import { type ComponentProps, type ComponentType } from "svelte";

  export type Column<Model, Component extends ComponentType = ComponentType> = {
    [Field in keyof Model]: {
      name: Field;
      label?: string;
      format?: (value: Model[Field], record: Model) => string;
      component?: Component;
      props?: (row: Model) => ComponentProps<InstanceType<Component>>;
      width?: number | string;
    };
  }[keyof Model];

  export type RowAction = {
    label: string;
    callback: (idx: number) => void;
    show?: boolean | ((idx: number) => boolean);
  };

  export type RowActions = {
    primary?: RowAction[];
    secondary?: RowAction[];
  };
</script>

<script lang="ts" generics="RowType">
  import type { PaginationData } from "$lib/backend";
  import { cn } from "$lib/utils";

  import EmptyState from "$components/EmptyState.svelte";

  import { Checkbox } from "$components/ui/checkbox";
  import * as Pagination from "$components/ui/pagination";
  import * as Table from "$components/ui/table";
  import * as DropdownMenu from "$components/ui/dropdown-menu";

  import MoreIcon from "~icons/ph/dots-three-vertical";

  export let title: string | null = null;
  export let subtitle: string | null = null;
  export let searchable: boolean = false;
  export let rows: RowType[];
  export let columns: Column<(typeof rows)[number]>[];
  export let rowActions: RowActions = {};
  export let emptyTitle: string | undefined = undefined;
  export let pagination: PaginationData | undefined = undefined;

  let wrapperClass = cn("bg-white border border-zinc-300", $$props.class);

  // This function renders a dynamic component with a dynamic prop key.
  // Returns an empty string because the return value is inserted in the DOM.
  // In other words, without a return value, `undefined` would be inserted on
  // the page.
  function buildComponent(
    column: Column<RowType>,
    row: RowType,
    target: HTMLElement,
  ) {
    if (
      !column.component ||
      !column.props ||
      target.innerHTML !== "undefined"
    ) {
      return "";
    }

    const props = column.props(row);

    new column.component({
      target,
      props,
    });

    return "";
  }

  function getColumnWidth(column: (typeof columns)[number]): string {
    if (typeof column.width === "string") {
      return column.width;
    } else if (typeof column.width === "number") {
      return `calc(100% * ${column.width} / 12)`;
    } else {
      return "auto";
    }
  }
</script>

<div class={wrapperClass}>
  <!-- Header -->
  <div class="flex items-center w-full">
    <div class="flex-grow p-2">
      {#if title}
        <h2 class="px-2 text-lg font-semibold leading-8">
          {title}
        </h2>
        {#if subtitle}
          <span class="text-sm text-zinc-500">{subtitle}</span>
        {/if}
      {/if}
    </div>

    <div class="p-2">
      {#if searchable}
        <!-- Search -->
      {/if}
      <slot name="actions" />
    </div>
  </div>

  <div>
    {#if rows.length === 0}
      <EmptyState
        title={emptyTitle}
        class="bg-zinc-100 border-t border-zinc-300"
      >
        <slot name="empty-content" />
      </EmptyState>
    {:else}
      <Table.Root
        wrapperClass="border-t border-zinc-300"
        class="w-full text-sm"
        embedded
      >
        <Table.Header>
          <Table.Row>
            <Table.Head class="w-16 px-4 text-center">
              <Checkbox class="bg-white" />
            </Table.Head>

            {#each columns as column}
              <Table.Head class="py-2 px-4">
                {column.label ?? column.name}
              </Table.Head>
            {/each}

            {#if rowActions.primary?.length}
              <Table.Head class="w-4"></Table.Head>
            {/if}

            {#if rowActions.secondary?.length}
              <Table.Head class="w-4"></Table.Head>
            {/if}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {#each rows as row, idx (idx)}
            <Table.Row>
              <Table.Cell class="px-4 text-center">
                <Checkbox />
              </Table.Cell>

              {#each columns as column (column.name)}
                <Table.Cell
                  class="py-3 px-4"
                  style={`width: ${getColumnWidth(column)};`}
                  let:element
                >
                  {#if column.component}
                    {element && buildComponent(column, row, element)}
                  {:else if column.format}
                    {@html column.format(row[column.name], row)}
                  {:else}
                    {row[column.name]}
                  {/if}
                </Table.Cell>
              {/each}

              {#if rowActions.primary?.length}
                <Table.Cell>
                  <div class="flex items-center gap-2 px-2">
                    {#each rowActions.primary as rowAction}
                      <button
                        type="button"
                        class="underline font-medium"
                        on:click={() => rowAction.callback(idx)}
                      >
                        {rowAction.label}
                      </button>
                    {/each}
                  </div>
                </Table.Cell>
              {/if}

              {#if rowActions.secondary?.length}
                <Table.Cell>
                  <div class="flex items-center gap-2 px-2">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger>
                        <MoreIcon class="me-2" />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content align="end">
                        {#each rowActions.secondary as rowAction}
                          {#if rowAction.show === undefined || rowAction.show === true || (rowAction.show !== false && rowAction.show(idx))}
                            <DropdownMenu.Item
                              on:click={() => rowAction.callback(idx)}
                            >
                              {rowAction.label}
                            </DropdownMenu.Item>
                          {/if}
                        {/each}
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </div>
                </Table.Cell>
              {/if}
            </Table.Row>
          {/each}
        </Table.Body>
      </Table.Root>
    {/if}

    {#if pagination}
      <Pagination.Root
        page={pagination.page}
        count={pagination.count}
        perPage={pagination.limit}
        class="p-2 bg-gradient-to-t from-zinc-200/80 to-zinc-100 border-t border-zinc-300"
        let:pages
        let:currentPage
      >
        <Pagination.Content>
          <Pagination.Item>
            <Pagination.PrevButton />
          </Pagination.Item>
          {#each pages as page (page.key)}
            {#if page.type === "ellipsis"}
              <Pagination.Item>
                <Pagination.Ellipsis />
              </Pagination.Item>
            {:else}
              <Pagination.Item>
                <Pagination.Link {page} isActive={currentPage == page.value}>
                  {page.value}
                </Pagination.Link>
              </Pagination.Item>
            {/if}
          {/each}
          <Pagination.Item>
            <Pagination.NextButton />
          </Pagination.Item>
        </Pagination.Content>
      </Pagination.Root>
    {/if}
  </div>
</div>
