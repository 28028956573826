<script lang="ts">
  import { onMount } from "svelte";
  import { inertia } from "@inertiajs/svelte";

  import DOWNLOAD_PROFILES from "$data/download_profiles.json";

  import API from "$api";
  import backend, { type PaginationData } from "$lib/backend";
  import { clearBag as clearBagStore } from "$stores/bag";
  import { selection } from "$stores/selection";
  import { currentUser } from "$stores/user";

  import AssetsPage from "$views/assets/_page.svelte";
  import EditAssetTagsDialog from "$views/asset_tags/edit.svelte";
  import NewPublicationDialog from "$views/publications/new.svelte";

  import Selection from "$components/Selection.svelte";
  import EmptyState from "$components/EmptyState.svelte";
  import { triggerConfirmation } from "$components/ConfirmationDialog.svelte";

  import * as Select from "$components/ui/select";
  import { Select as SelectPrimitive } from "bits-ui";
  import { Button } from "$components/ui/button";

  import BagIcon from "~icons/ph/bag";
  import TrashIcon from "~icons/ph/trash";
  import DownloadIcon from "~icons/ph/download-fill";
  import EditIcon from "~icons/ph/pencil-fill";
  import ShareIcon from "~icons/ph/share-fat-fill";

  export let assets: Schema.Asset[] = [];
  export let pagination: PaginationData;

  let selectionComponent: Selection | undefined = undefined;
  let totalSize = 0;

  onMount(() => {
    updateTotalSize();
  });

  function updateTotalSize() {
    totalSize = 0;
    for (const asset of assets) {
      totalSize += asset.file.metadata.size ?? 0;
    }
  }

  function clearBag() {
    triggerConfirmation({
      title: "Confirm action",
      description: "Are you sure you want to clear the assets in your bag?",
      confirmBtnLabel: "Yes, clear them",
    }).then(({ confirmed }) => {
      if (confirmed) clearBagStore();
    });
  }

  function editAssetTags() {
    if ($selection.size === 0) {
      return alert("Please select one or more assets to edit their tags.");
    }

    // The selected assets must stay so. Disable outside click detection.
    selectionComponent?.disableOCD();

    const selectedAssets = assets.filter((_, idx) => $selection.has(idx));

    const dialog = new EditAssetTagsDialog({
      target: document.body,
      props: { assets: selectedAssets },
    });

    dialog.$on("destroy", () => {
      selectionComponent?.enableOCD();
      dialog.$destroy();
    });
  }

  async function download(profile?: string) {
    if ($selection.size === 0) {
      selectionComponent?.enableOCD();
      return alert("Please select one or more assets to download.");
    }

    let selectedAssets = assets.filter((_, idx) => $selection.has(idx));
    let endpoint: string | undefined = undefined;

    if (selectedAssets.length === 1) {
      endpoint = API.assets.download.path({ id: selectedAssets[0].id });
    } else {
      const assetPack: Schema.AssetPack = await backend.post(
        API.assetPacks.create.path(),
        {
          asset_pack: {
            asset_pack_assets_attributes: selectedAssets.map((asset) => ({
              asset_id: asset.id,
            })),
          },
        },
      );

      endpoint = API.assetPacks.download.path({ id: assetPack.id });
    }

    if (endpoint) {
      const url = new URL(endpoint, window.location.href);
      if (profile) url.searchParams.append("format", profile);
      window.location.href = url.toString();
    }

    selectionComponent?.enableOCD();
  }

  async function publishAsset() {
    if ($selection.size === 0) {
      return alert("Please select one or more assets to publish.");
    }

    // The selected assets must stay so. Disable outside click detection.
    selectionComponent?.disableOCD();

    const selectedAssets = assets.filter((_, idx) => $selection.has(idx));

    const dialog = new NewPublicationDialog({
      target: document.body,
      props: { assets: selectedAssets },
    });

    dialog.$on("destroy", () => {
      selectionComponent?.enableOCD();
      dialog.$destroy();
    });
  }
</script>

<div class="grid grid-rows-[auto_minmax(0,1fr)] h-full">
  <div
    class="z-50 flex gap-4 py-2 px-4 bg-zinc-200 border-b border-zinc-400 shadow-t-line"
  >
    <div class="flex items-center w-1/3 font-semibold">
      <BagIcon class="me-2" /> My Bag
    </div>

    <div class="flex items-center justify-center gap-1 w-1/3">
      <div class="btn-group">
        {#if $currentUser && ["admin", "contributor"].includes($currentUser.role)}
          <Button variant="action-bar" class="px-3" on:click={editAssetTags}>
            <EditIcon class="me-2" />
            Tags
          </Button>
        {/if}

        {#if $currentUser && ["admin", "contributor"].includes($currentUser.role)}
          <Button variant="action-bar" class="px-3" on:click={publishAsset}>
            <ShareIcon class="me-2" />
            Publish
          </Button>
        {/if}

        <Select.Root portal={null}>
          <SelectPrimitive.Trigger asChild let:builder>
            <Button
              builders={[builder]}
              variant="action-bar"
              class="px-3 !rounded-r-md"
              on:click={() => selectionComponent?.disableOCD()}
            >
              <DownloadIcon class="me-2" />
              Download
            </Button>
          </SelectPrimitive.Trigger>
          <Select.Content class="w-fit text-sm" sameWidth={false}>
            <Select.Group>
              <Select.Label>Choose a format</Select.Label>
              {#each DOWNLOAD_PROFILES["Photo"] as downloadProfile}
                <Select.Item
                  value={downloadProfile.format}
                  label={downloadProfile.label}
                  hasItemIndicator={false}
                  on:click={() => download(downloadProfile.format)}
                >
                  {downloadProfile.label}
                </Select.Item>
              {/each}
              <Select.Item
                value="original"
                label="Original"
                hasItemIndicator={false}
                on:click={() => download()}
              >
                Original
              </Select.Item>
            </Select.Group>
          </Select.Content>
          <Select.Input name="favoriteFruit" />
        </Select.Root>
      </div>
    </div>

    <div class="flex items-center justify-end gap-1 w-1/3">
      <Button variant="action-bar" class="px-3" on:click={clearBag}>
        <TrashIcon class="me-2" />
        Empty Bag
      </Button>
    </div>
  </div>

  <AssetsPage {assets} {pagination} bind:selectionComponent>
    <svelte:fragment slot="empty-state">
      <EmptyState title="No assets">
        Click <a
          href={API.assets.index.path()}
          class="underline hover:text-primary"
          use:inertia
        >
          here
        </a> to see them all.
      </EmptyState>
    </svelte:fragment>
  </AssetsPage>
</div>
