<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";

  import TagFieldForm from "$views/admin/tag_fields/_form.svelte";

  import API from "$api";
  import { toast } from "$lib/actions";

  import Spinner from "$components/Spinner.svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";

  import XIcon from "~icons/ph/x";

  export let tag_field: Schema.TagField;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();
  const form = useForm(tag_field);

  let open: boolean = false;
  let saving: boolean = false;

  onMount(() => {
    open = true;
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  function onSaveClick() {
    saving = true;

    // Namespace the form data before sending it to the backend, as Rails
    // requires it (strong parameters).
    const railsForm = $form.transform((data: Record<string, unknown>) => {
      return { tag_field: data };
    });

    railsForm.put(API.adminTagFields.update.path({ id: tag_field.id }), {
      onSuccess: () => {
        saving = false;
        open = false;
        toast(`Tag <b>${$form.name}</b> was updated.`, { style: "success" });
      },
      onFinish: () => {
        saving = false;
      },
    });

    saving = true;
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Trigger />
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content
      class="block md:w-1/2 xl:w-1/4 max-w-none p-0 bg-zinc-100 border-none overflow-hidden"
    >
      <Dialog.Header class="flex-row space-y-0 bg-primary">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          Edit Tag Field
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      <div class="py-6 px-4">
        <TagFieldForm {form} />
      </div>

      <Dialog.Footer class="p-2 bg-zinc-200 border-t border-zinc-300">
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button disabled={saving} on:click={onSaveClick}>
          {#if saving}
            <Spinner />
          {:else}
            Save
          {/if}
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
