<script lang="ts">
  import { cn } from "$lib/utils";

  import ErrorIcon from "~icons/ph/x-circle-fill";
  import InfoIcon from "~icons/ph/info-fill";

  export let title: string | undefined = undefined;
  export let type: "error" | "info" | "success" | "warning" = "info";
  export let hasIcon: boolean = true;

  let wrapperClass: string = cn(
    "flex items-center p-4 mb-4 text-sm rounded-lg",
    getWrapperStyle(),
    $$props.class,
  );

  function getWrapperStyle(): string {
    switch (type) {
      case "info":
        return "bg-blue-50 dark:bg-zinc-800 text-blue-800 dark:text-blue-400";
      case "warning":
        return "bg-yellow-50 dark:bg-zinc-800 text-yellow-800 dark:text-yellow-400";
      case "error":
        return "bg-red-50 dark:bg-zinc-800 text-red-800 dark:text-red-400";
      case "success":
        return "bg-green-50 dark:bg-zinc-800 text-green-800 dark:text-green-400";
      default:
        return "bg-zinc-100 dark:bg-zinc-800 text-zinc-800 dark:text-zinc-300";
    }
  }
</script>

<div class={wrapperClass} role="alert">
  {#if hasIcon}
    <div class="flex-shrink-0 inline w-4 h-4 me-3">
      {#if $$slots.icon}
        <slot name="icon" />
      {:else if type === "error"}
        <ErrorIcon class="w-5 h-5 text-red-800" />
      {:else}
        <InfoIcon class="w-5 h-5 text-zinc-800" />
      {/if}
    </div>
  {/if}

  <span class="sr-only">Info</span>

  <div>
    <slot />
  </div>
</div>
