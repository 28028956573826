<script lang="ts">
  import { fade, fly } from "svelte/transition";
  import { flip } from "svelte/animate";

  import { toast } from "./stores";
  import Toast, { type ToastOptions } from "./Toast.svelte";

  export let options: Partial<ToastOptions> = {};
  export let target: string = "default";

  let items: Array<ToastOptions> = [];

  $: toast._init(target, options);

  $: items = $toast.filter((i: ToastOptions) => i.target === target);
</script>

<ul
  class="z-[9999] fixed top-6 right-8 bottom-auto left-auto m-0 p-0 list-none pointer-events-none"
>
  {#each items as item (item.id)}
    <li
      class={item.classes?.join(" ")}
      in:fly={item.intro}
      out:fade={{ duration: 200 }}
      animate:flip={{ duration: 200 }}
    >
      <Toast {item} />
    </li>
  {/each}
</ul>
