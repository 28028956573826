<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  import API from "$api";
  import backend, { ValidationError } from "$lib/backend";

  import Input from "$components/forms/Input.svelte";
  import ResponsiveAssetImage from "$components/ResponsiveAssetImage.svelte";
  import DateInput from "$components/forms/DateInput.svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";
  import { Label } from "$components/ui/label";
  import { Checkbox } from "$components/ui/checkbox";
  import * as Popover from "$components/ui/popover";

  import XIcon from "~icons/ph/x";
  import CopyIcon from "~icons/ph/copy-bold";
  import CheckIcon from "~icons/ph/check";
  import CaretDownIcon from "~icons/ph/caret-down-bold";

  export let assets: Schema.Asset[];

  const dispatch = createEventDispatcher<{ destroy: undefined }>();

  let publication: Partial<Schema.Publication> = {
    title: undefined,
    expires_on: undefined,
    email_recipients: undefined,
  };

  let open: boolean = false;
  let saving: boolean = false;
  let hasExpiry: boolean = false;
  let sendEmail: boolean = false;
  let shareLinkCopied: boolean = false;
  let emailInput: HTMLInputElement | undefined = undefined;

  onMount(() => {
    open = true;
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onSaveClick() {
    saving = true;

    if (assets.length === 1) {
      publication.publishable_type = "Asset";
      publication.publishable_id = assets[0].id;
    } else {
      publication.publishable_type = "AssetPack";
      publication.publishable_attributes = {
        asset_pack_assets_attributes: assets.map((asset) => {
          return { asset_id: asset.id };
        }),
      };
    }

    backend
      .post(API.publications.create.path(), { publication })
      .then((newPublication: Schema.Publication) => {
        publication = newPublication;
      })
      .catch((error) => {
        if (error instanceof ValidationError) {
          publication = error.data as Schema.Publication;
        }
      });
  }

  async function copyLink(link: string) {
    try {
      await navigator.clipboard.writeText(link);
      shareLinkCopied = true;
      setTimeout(() => (shareLinkCopied = false), 2000);
    } catch (error) {
      console.error("Could not copy publication link to clipboard.", error);
    }
  }

  function addRecipient() {
    if (!emailInput) return;

    if (publication.errors?.email_recipients) {
      // Reset error messages.
      publication.errors.email_recipients = undefined;
    }

    if (emailInput.validity.valid) {
      publication.email_recipients ??= [];
      publication.email_recipients = publication.email_recipients.concat(
        emailInput.value.split(","),
      );
      emailInput.value = "";
    } else {
      publication.errors ??= { full_messages: [] };
      publication.errors.email_recipients = [emailInput.validationMessage];
    }
  }

  function removeRecipient(address: string) {
    publication.email_recipients = publication.email_recipients?.filter(
      (a) => a !== address,
    );
  }
</script>

<Dialog.Root bind:open>
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content class="block p-0 bg-zinc-100 border-none">
      <Dialog.Header class="flex-row space-y-0 bg-primary rounded-t-lg">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          {publication.encoded_id ? "Published" : "Publishing"}
          {assets.length}
          {assets.length === 1 ? "asset" : "assets"}
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      {#if publication.encoded_id}
        {@const url = new URL(
          API.publications.show.path({ encoded_id: publication.encoded_id }),
          window.location.href,
        )}
        <div class="p-4">
          <h2 class="font-semibold">Public link</h2>

          <div class="flex items-center gap-2 w-full">
            <span
              class="flex-grow py-1 px-2 bg-zinc-200 border border-zinc-300 rounded-md overflow-x-auto"
            >
              {url.toString()}
            </span>

            <Button
              variant="secondary"
              class="flex-shrink-0 flex items-center gap-1"
              on:click={() => copyLink(url.toString())}
              disabled={shareLinkCopied}
            >
              {#if shareLinkCopied}
                <CheckIcon class="text-green-600" /> Copied
              {:else}
                <CopyIcon /> Copy
              {/if}
            </Button>
          </div>
        </div>
      {:else}
        <div class="w-full p-4 pb-0">
          {#if assets.length > 1}
            <div
              class="w-[calc(100%-1rem)] mx-auto pb-2 bg-white border rounded-lg shadow-md shadow-zinc-300"
            >
              <ResponsiveAssetImage
                asset={assets[0]}
                width={500}
                loadedClass="w-[calc(100%+1rem)] -ml-2 p-2 bg-white border rounded-lg shadow-md shadow-zinc-300"
                class="w-full object-contain rounded-md"
              />
            </div>
          {:else}
            <ResponsiveAssetImage
              asset={assets[0]}
              width={500}
              loadedClass="w-full p-2 bg-white border rounded-lg shadow-md shadow-zinc-300"
              class="w-full object-contain rounded-md"
            />
          {/if}
        </div>

        <div class="p-4 space-y-2">
          <div>
            <Label>Title</Label>
            <Input
              name="title"
              hint="Shown on the download page"
              error={publication.errors?.title &&
                `Title ${publication.errors?.title[0]}`}
              bind:value={publication.title}
            />
          </div>

          <div class="flex items-center">
            <div class="flex-grow flex items-center gap-2 py-2">
              <Checkbox id="has_expiry" bind:checked={hasExpiry} />
              <Label for="has_expiry">Set an expiry date</Label>
            </div>
            {#if hasExpiry}
              <div class="flex-shrink-0">
                <DateInput
                  placeholder="Select a date"
                  wrapperClass="items-center gap-1 text-sm font-medium"
                  placeholderClass="self-baseline"
                  bind:value={publication.expires_on}
                >
                  <svelte:fragment slot="trailing">
                    <CaretDownIcon class="flex-shrink-0 w-3 h-3" />
                  </svelte:fragment>
                </DateInput>
              </div>
            {/if}
          </div>

          <div class="flex items-center">
            <div class="flex-grow flex items-baseline gap-2">
              <Checkbox id="send_email" bind:checked={sendEmail} />
              <Label for="send_email">Send link by e-mail</Label>
            </div>
            {#if sendEmail}
              <div class="flex-shrink-0">
                <Popover.Root open={true}>
                  <Popover.Trigger
                    class="flex items-center gap-1 text-sm font-medium"
                  >
                    {#if publication.email_recipients?.length}
                      {@const recipientsCount =
                        publication.email_recipients.length}
                      {recipientsCount}
                      {recipientsCount === 1 ? "recipient" : "recipients"}
                    {:else}
                      Add a recipient
                    {/if}
                    <CaretDownIcon class="w-3 h-3" />
                  </Popover.Trigger>
                  <Popover.Content
                    align="end"
                    class="bg-zinc-100 border-zinc-300 shadow-black/20 overflow-hidden"
                  >
                    <Input
                      type="email"
                      name="recipients"
                      placeholder="Enter e-mail address"
                      hint="Press Enter to add the address"
                      error={publication.errors?.email_recipients &&
                        publication.errors?.email_recipients[0]}
                      wrapperClass="py-1 px-2 border-zinc-300"
                      class="text-sm"
                      multiple
                      on:enter={addRecipient}
                      bind:elem={emailInput}
                    />

                    {#if publication.email_recipients?.length}
                      <ul
                        class="flex flex-wrap gap-2 -m-4 mt-2 p-4 bg-zinc-200 border-t border-zinc-300 text-sm"
                      >
                        {#each publication.email_recipients as recipient (recipient)}
                          <li
                            class="flex gap-1 py-0.5 pl-2 pr-1 bg-blue-600 rounded-md text-white"
                          >
                            {recipient}
                            <button on:click={() => removeRecipient(recipient)}>
                              <XIcon class="w-3 h-3 mt-0.5" />
                            </button>
                          </li>
                        {/each}
                      </ul>
                    {/if}
                  </Popover.Content>
                </Popover.Root>
              </div>
            {/if}
          </div>
        </div>
      {/if}

      <Dialog.Footer
        class="p-2 bg-zinc-200 border-t border-zinc-300 rounded-b-lg"
      >
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button on:click={onSaveClick}>Publish</Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
