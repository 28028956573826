<script context="module" lang="ts">
  import { writable, type Writable } from "svelte/store";

  export type DragnDropContext = {
    isDropAllowed: Writable<boolean>;
    dropTip: Writable<string | undefined>;
    dropped: Writable<boolean>;
    data: Writable<unknown | undefined>;
  };

  export const appFooter = writable<HTMLElement | undefined>(undefined);
</script>

<script lang="ts">
  import { setContext } from "svelte";
  import { fade } from "svelte/transition";
  import { inertia, page, router } from "@inertiajs/svelte";

  import API from "$api";
  import { datetime } from "$lib/utils";
  import { currentUser } from "$stores/user";
  import { initBagStore } from "$stores/bag";
  import { initSearchEngine } from "$stores/asset_search";
  import { importFiles, importFolders } from "$stores/asset_import";

  import logoUrl from "$assets/images/logo-alt.svg";

  import SearchBox from "$components/SearchBox.svelte";
  import UserMenu from "$components/UserMenu.svelte";
  import BagWidget from "$components/BagWidget.svelte";

  import * as DropdownMenu from "$components/ui/dropdown-menu";
  import { Toaster } from "$components/toast";

  const dndCtx: DragnDropContext = {
    isDropAllowed: writable(false),
    dropTip: writable(undefined),
    dropped: writable(false),
    data: writable(undefined),
  };

  let header: HTMLElement;

  setContext("dragndrop", dndCtx);

  currentUser.set($page?.props.current_user as Schema.User);
  if ($currentUser) {
    initBagStore();
    initSearchEngine();
  } else {
    console.error("Could not get user details.");
  }
</script>

<section class="grid grid-rows-[auto_minmax(0,1fr)] w-screen h-screen">
  <header
    class="z-[60] flex items-center h-14 px-4 bg-gradient-to-t from-zinc-200 to-zinc-150 border-b border-zinc-400"
    bind:this={header}
  >
    <a href="/" use:inertia>
      <img src={logoUrl} alt="Logo" class="w-32" />
    </a>

    <nav class="flex items-center space-x-4 w-1/3 px-8 text-sm font-semibold">
      <a href={API.home.index.path()} class="hover:text-zinc-600" use:inertia>
        Home
      </a>

      {#if $currentUser && ["admin", "contributor"].includes($currentUser.role)}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            class="-mx-4 py-0.5 px-3 data-[state='open']:bg-zinc-300 rounded"
          >
            Import
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            class="z-[61]"
            align="start"
            sideOffset={2}
            transition={undefined}
            outTransition={fade}
            outTransitionConfig={{ duration: 150 }}
          >
            <DropdownMenu.Item on:click={importFiles}>Files</DropdownMenu.Item>
            <DropdownMenu.Item on:click={importFolders}>
              Folder
            </DropdownMenu.Item>
            {#if $currentUser.imports?.length}
              {@const mostRecentImports = $currentUser.imports.slice(0, 3)}
              {@const olderImports = $currentUser.imports.slice(3, 23)}

              <DropdownMenu.Separator />
              <DropdownMenu.Label>Latest imports</DropdownMenu.Label>
              {#each mostRecentImports as recentImport}
                <DropdownMenu.Item
                  on:click={() =>
                    router.visit(
                      API.imports.show.path({ id: recentImport.id }),
                    )}
                >
                  {datetime.format(recentImport.created_at, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  })}
                </DropdownMenu.Item>
              {/each}
              {#if olderImports.length}
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>More</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    {#each olderImports as olderImport}
                      <DropdownMenu.Item
                        on:click={() =>
                          router.visit(
                            API.imports.show.path({ id: olderImport.id }),
                          )}
                      >
                        {datetime.format(olderImport.created_at, {
                          dateStyle: "medium",
                          timeStyle: "short",
                        })}
                      </DropdownMenu.Item>
                    {/each}
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
              {/if}
            {/if}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      {/if}

      {#if $currentUser?.role === "admin"}
        <a
          href={API.adminDashboard.index.path()}
          class="hover:text-zinc-600"
          use:inertia
        >
          Admin
        </a>
      {/if}
    </nav>

    <div class="w-1/3">
      <SearchBox />
    </div>

    <div class="flex items-center justify-end gap-4 w-1/3">
      <BagWidget class="self-stretch my-1" />
      <UserMenu />
    </div>
  </header>

  <div class="relative bg-white">
    <slot />

    <Toaster />
  </div>
</section>
