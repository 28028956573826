<script lang="ts">
  import { createEventDispatcher, getContext, hasContext } from "svelte";
  import { get } from "svelte/store";
  import type { HTMLInputTypeAttribute } from "svelte/elements";

  import { cn } from "$lib/utils";

  import IconEye from "~icons/ph/eye";
  import IconEyeSlash from "~icons/ph/eye-slash";
  import ClearIcon from "~icons/ph/x-bold";

  export let type: HTMLInputTypeAttribute = "text";
  export let name: string;
  export let canTogglePassword: boolean = true;
  export let clearable: boolean = false;
  export let value: string | null = null;
  export let disabled: boolean = false;
  export let hint: string | undefined = undefined;
  export let error: string | undefined = undefined;
  export let wrapperClass: string | undefined = undefined;
  export let elem: HTMLInputElement | undefined = undefined;
  let inputClass: string | undefined = undefined;
  export { inputClass as class };

  const dispatch = createEventDispatcher<{
    backspace: typeof value;
    blur: typeof value;
    clear: undefined;
    enter: typeof value;
  }>();

  let isPasswordField = type === "password"; // cache used for togglePassword
  let form: FormContext | undefined = undefined;

  $: wrapperClass = cn(
    "flex items-center py-2 px-3 bg-white border border-input focus-within:border-primary focus-within:ring-1 ring-primary rounded",
    wrapperClass,
  );
  $: inputClass = cn("w-full bg-transparent outline-none", inputClass);

  if (hasContext("form")) {
    form = getContext<FormContext>("form");

    form.subscribe((newRecord) => {
      error = newRecord.errors[name];
    });

    const initialValue = get(form).data()[name];
    if (initialValue) value = initialValue as string;
  }

  function togglePassword() {
    if (type === "text") type = "password";
    else type = "text";
  }

  function onInput({ target }: { target: EventTarget | null }) {
    const newValue = (target as HTMLInputElement).value;

    if (form && name in get(form).data()) {
      form.update((curr) => {
        return { ...curr, [name]: newValue };
      });
    }

    value = newValue;
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") dispatch("enter", value);
    else if (event.key === "Backspace") dispatch("backspace", value);
  }

  function onBlur() {
    dispatch("blur", value);
  }

  function onClearClick() {
    value = null;
    dispatch("clear");
  }
</script>

<div>
  <div class={wrapperClass} class:border-red-500={error}>
    <slot name="leading" />

    <input
      {type}
      {name}
      {value}
      {disabled}
      {...$$restProps}
      class={inputClass}
      on:input={onInput}
      on:keydown={onKeyDown}
      on:keydown
      on:blur={onBlur}
      bind:this={elem}
    />

    {#if isPasswordField && canTogglePassword}
      <span on:click={togglePassword} role="button" tabindex="0">
        {#if type === "text"}
          <IconEyeSlash />
        {:else}
          <IconEye />
        {/if}
      </span>
    {/if}

    {#if clearable && value && value.trim() !== ""}
      <button class="text-zinc-400 hover:text-primary" on:click={onClearClick}>
        <ClearIcon class="text-sm" />
      </button>
    {/if}

    <slot name="trailing" />
  </div>

  {#if error}
    <div class="flex gap-1 mt-1">
      <span class="text-xs text-red-500 font-medium">{error}</span>
    </div>
  {/if}

  {#if hint}
    <span class="block mt-1 text-xs text-zinc-500">{hint}</span>
  {:else if $$slots.hint}
    <slot name="hint" />
  {/if}
</div>
