import type { ComponentProps, ComponentType } from "svelte";
import { get, type Writable } from "svelte/store";
import { toast as toastStack, type ToastOptions } from "$components/toast";

import ASSET_TYPES from "$data/asset_types.json";

import API from "$api";
import backend from "$lib/backend";
import type { ShowOpenFilePickerOpts } from "$lib/polyfills";
import { currentUser } from "$stores/user";

import Dialog from "$components/Dialog.svelte";
import ErrorDialog from "$components/ErrorDialog.svelte";

type ClickOutsideOptions = {
  onClickOutside?: ((event: MouseEvent) => void) | (() => void);
  considerInside?: ConsiderableInside;
};

export type ConsiderableInside =
  | HTMLElement
  | HTMLElement[]
  | undefined
  | Writable<HTMLElement | HTMLElement[] | undefined>;

export function createDialog<T extends ComponentType>(
  props: ComponentProps<Dialog<T>>,
) {
  const dialog = new Dialog({
    target: document.body,
    props,
  });

  return dialog;
}

type AssetType = {
  name: string;
  mime_types: string[];
};
export function pickFiles(opts?: ShowOpenFilePickerOpts) {
  opts ??= {};
  opts.types ??= ASSET_TYPES.map((at: AssetType) => {
    return {
      description: at.name,
      accept: Object.fromEntries(at.mime_types.map((mime) => [mime, ["*"]])),
    };
  });

  return window.showOpenFilePicker(opts);
}

/* Svelte Actions */

export function portal(node: HTMLElement, target?: HTMLElement | string) {
  target ??= document.body;

  function update(newTarget: HTMLElement | string) {
    target = newTarget;

    if (typeof target === "string") {
      target = (document.querySelector(target) as HTMLElement) ?? document.body;
    }

    target.appendChild(node);
  }

  update(target);

  return {
    update,
    destroy: () => {
      if (node.parentNode) node.parentNode.removeChild(node);
    },
  };
}

// This action detects if the user clicks outside a given element.
// Specific elements that are outside might be "considered inside" by passing
// them in `options`.
export function outsideClickDetection(
  element: HTMLElement,
  options?: ClickOutsideOptions,
) {
  function detect(event: MouseEvent) {
    if (!event.target || !options || !options.onClickOutside) return;

    const target = event.target as HTMLElement;

    // Check if click is inside.
    if (element.contains(target)) return;
    if (options.considerInside) {
      const considerInside = getElementsArray(options.considerInside);

      const isInside = considerInside.some((element) => {
        return (
          element && (target.isSameNode(element) || element.contains(target))
        );
      });

      if (isInside) return;
    }

    // Reaching here means the click is outside.
    options.onClickOutside(event);
  }

  // `passive` helps with scroll performance.
  // `capture` ensures all clicks are caught.
  document.addEventListener("click", detect, { passive: true, capture: true });

  return {
    destroy() {
      document.removeEventListener("click", detect);
    },
  };
}

function getElementsArray(elements: ConsiderableInside): HTMLElement[] {
  if (!elements) {
    return [];
  } else if (elements instanceof HTMLElement) {
    return [elements];
  } else if (Array.isArray(elements)) {
    return elements;
  } else {
    return getElementsArray(get(elements));
  }
}

export function triggerErrorDialog(props: ComponentProps<ErrorDialog>) {
  const dialog = new ErrorDialog({
    target: document.body,
    props,
  });

  dialog.$on("destroy", () => dialog.$destroy());
}

export function toast(message: string, opts?: Partial<ToastOptions>) {
  toastStack.push(message, opts);
}
