import { writable } from "svelte/store";

import type { ToastOptions } from "./Toast.svelte";

const defaults: Partial<ToastOptions> = {
  duration: 4000,
  initial: 1,
  next: 0,
  pausable: false,
  dismissable: true,
  reversed: false,
  intro: { x: 256 },
  progress: false,
  icon: true,
};

function createToast() {
  const { subscribe, update } = writable(new Array());
  const options: Record<string, Partial<ToastOptions>> = {};

  let count = 0;

  function _obj(obj: unknown): boolean {
    return obj instanceof Object;
  }

  function _init(target: string = "default", opts: Partial<ToastOptions> = {}) {
    options[target] = opts;
    return options;
  }

  /** Send a new toast */
  function push(msg: string, opts?: Partial<ToastOptions>): number {
    opts ??= {};
    opts.msg = msg;

    const param: Partial<ToastOptions> & { target: string } = {
      target: "default",
    };
    if (_obj(msg)) Object.assign(param, msg);
    else Object.assign(param, opts);

    const conf = options[param.target] || {};
    const entry = {
      ...defaults,
      ...conf,
      ...param,
      theme: { ...conf.theme, ...param.theme },
      classes: [...(conf.classes || []), ...(param.classes || [])],
      id: ++count,
    } as ToastOptions;

    update((n: ToastOptions[]) =>
      entry.reversed ? [...n, entry] : [entry, ...n],
    );

    return count;
  }

  /**
   * Remove toast(s)
   *
   * - Toast.pop() // removes the last toast
   * - Toast.pop(0) // remove all toasts
   * - Toast.pop(id) // removes the toast with specified `id`
   * - Toast.pop({ target: 'foo' }) // remove all toasts from target `foo`
   */
  function pop(id: number | { target: string }) {
    update((n) => {
      if (!n.length || id === 0) return [];
      if (typeof id !== "number" && "target" in id) {
        return n.filter((i: ToastOptions) => i.target !== id.target);
      }

      const found = id || Math.max(...n.map((i) => i.id));

      return n.filter((i) => i.id !== found);
    });
  }

  /** Update an existing toast */
  function set(id: number | ToastOptions, opts: ToastOptions) {
    const param: ToastOptions = typeof id === "number" ? { ...opts, id } : id;

    update((n) => {
      const idx = n.findIndex((i) => i.id === param.id);
      if (idx > -1) {
        n[idx] = { ...n[idx], ...param };
      }

      return n;
    });
  }

  return { subscribe, push, pop, set, _init };
}

export const toast = createToast();
