<script lang="ts">
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { page } from "@inertiajs/svelte";

  import logoUrl from "$assets/images/logo.svg";

  import Alert from "$components/Alert.svelte";

  export let errors: Record<string, string> | undefined = undefined;

  const observer = new MutationObserver((mutations) => {
    const mutation = mutations.find((m) => m.type === "childList");

    if (mutation) {
      // If there is a new child in wrapper, e.g. an error alert, update its
      // height.
      updateHeight();
    }
  });

  let wrapper: HTMLDivElement;
  let content: HTMLDivElement;

  $: pathname = $page?.url ?? window.location.href;

  onMount(() => {
    // This observer reacts to changes in the subtree of the wrapper, e.g. when
    // an error alert is shown to the user.
    observer.observe(wrapper, { childList: true, subtree: true });

    // Set initial wrapper height.
    updateHeight();

    return () => observer.disconnect();
  });

  // This function updates the height of the wrapper.
  function updateHeight() {
    content = wrapper.firstElementChild as HTMLDivElement;
    wrapper.style.height = `calc(${content.clientHeight}px + 4rem)`;
  }

  function getXIn() {
    switch (pathname) {
      case "/login":
        return "-50%";
      case "/forgot_password":
        return "50%";
      default:
        return "50%";
    }
  }

  function getXOut() {
    switch (pathname) {
      case "/login":
        return "50%";
      case "/forgot_password":
        return "-50%";
      default:
        return "-50%";
    }
  }
</script>

<section class="flex justify-center w-screen h-screen">
  <main class="w-96 mt-32">
    <img src={logoUrl} class="w-full" alt="Logo" />

    {#if $page?.props.flash}
      {#each Object.keys($page.props.flash) as key}
        <Alert type={key} class="mt-8 -mb-4">
          {$page.props.flash[key]}
        </Alert>
      {/each}
    {/if}

    <div
      class="absolute w-96 min-h-0 mt-8 p-8 bg-zinc-50 border border-zinc-300 rounded-md shadow-lg overflow-hidden transition-all duration-300"
      bind:this={wrapper}
    >
      {#key pathname}
        <div
          class="absolute w-80"
          in:fly={{ x: getXIn(), duration: 300, delay: 100 }}
          out:fly={{ x: getXOut(), duration: 300 }}
        >
          <slot />
        </div>
      {/key}
    </div>
  </main>
</section>
