<script lang="ts">
  import { DropdownMenu as DropdownMenuPrimitive } from "bits-ui";
  import { cn } from "$lib/utils.js";

  type $$Props = DropdownMenuPrimitive.SubContentProps;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<DropdownMenuPrimitive.SubContent
  class={cn(
    "z-50 min-w-32 max-w-64 rounded-md border bg-popover p-1 text-popover-foreground shadow-lg focus:outline-none truncate",
    className,
  )}
  {...$$restProps}
  on:keydown
  on:focusout
  on:pointermove
>
  <slot />
</DropdownMenuPrimitive.SubContent>
