<script lang="ts">
  import { onMount } from "svelte";
  import { page, router } from "@inertiajs/svelte";

  import API from "$api";
  import backend from "$lib/backend";
  import { cn } from "$lib/utils";
  import {
    applyFilters,
    getFilters,
    getQuery,
    initSearchEngine,
    removeFilter,
    setQuery,
    upsertFilter,
    clear as clearSearch,
  } from "$stores/asset_search";

  import TagFilter from "$components/TagFilter.svelte";
  import Input from "$components/forms/Input.svelte";

  import SearchIcon from "~icons/ph/magnifying-glass-bold";
  import CaretRight from "~icons/ph/caret-right-fill";

  export let wrapperClass: string | undefined = undefined;

  type Suggestions = {
    tags: Array<{
      tag_field_id: number;
      tag_field_name: string;
      value: string;
    }>;
  };

  let input: HTMLInputElement;
  let leading: HTMLDivElement;
  let open: boolean = false;
  let debouncer: ReturnType<typeof setTimeout>;

  let query: string;
  let suggestions: Suggestions = { tags: [] };

  let selectedSuggestion: number | undefined = undefined;
  let selectedSuggestionId: string = "";

  wrapperClass = cn("relative", wrapperClass);

  $: open = suggestions.tags.length > 0;
  $: if (query?.trim().length) {
    debounce(suggest);
  } else {
    suggestions.tags = [];
  }
  $: if (selectedSuggestion === undefined) {
    selectedSuggestionId = "";
  } else {
    const tag = suggestions.tags[selectedSuggestion];
    selectedSuggestionId = `${tag.tag_field_name}_${tag.value}`;
  }

  onMount(() => {
    initSearchEngine();

    router.on("navigate", () => updateField());
  });

  function updateField() {
    clear();

    const filters = getFilters();

    for (const filter of filters) {
      if (filter.attribute === "tags" && $page?.props.tag_fields) {
        const tags = filter.value as Record<string, any>;
        const tag_fields = $page.props.tag_fields as Schema.TagField[];

        for (const key in tags) {
          const tag_field_id = parseInt(key);
          const tag_field = tag_fields.find((tf) => tf.id === tag_field_id);

          addTagFilter({
            tag_field_id,
            tag_field_name: tag_field?.name ?? "",
            value: tags[key],
          });
        }
      }
    }

    query = getQuery();
  }

  function debounce(callback: Function, waitMs: number = 300) {
    clearTimeout(debouncer);
    debouncer = setTimeout(callback, waitMs);
  }

  function search() {
    setQuery(query);
    closeSuggestions();
  }

  function closeSuggestions() {
    open = false;
  }

  function clear() {
    leading.innerHTML = "";
  }

  function suggest() {
    if (!query) return;

    backend
      .get(API.searchSuggestions.new.path(), { query })
      .then((results: Suggestions) => {
        suggestions = results;
      });
  }

  function reset() {
    query = "";
    suggestions = { tags: [] };
    selectedSuggestion = undefined;
    open = false;
  }

  function selectTagFilter(tag: Suggestions["tags"][number]) {
    reset();
    addTagFilter(tag);
    applyFilters();
  }

  function addTagFilter(tag: Suggestions["tags"][number]) {
    const filter = { [tag.tag_field_id]: tag.value };
    const tagFilter = new TagFilter({
      target: leading,
      props: {
        tagFieldId: tag.tag_field_id,
        tagFieldName: tag.tag_field_name,
        value: tag.value,
      },
    });

    tagFilter.$on("destroy", () => {
      removeFilter("tags", filter);
      applyFilters();
      tagFilter.$destroy();
      input.focus();
    });

    upsertFilter("tags", filter);

    input.focus();
  }

  function onBackspace() {
    if (!query?.trim().length) {
      const lastFilter = leading.querySelector(
        "div:last-child > button:first-child",
      ) as HTMLElement | undefined;

      lastFilter?.focus();
    }
  }
</script>

<div class={wrapperClass}>
  <Input
    name="search_query"
    placeholder="Search Media Hub"
    wrapperClass="gap-2 p-1 border-2 border-zinc-300 focus-within:border-sky-600 focus-within:ring-2 focus-within:ring-sky-600/10 rounded-lg"
    class="py-1 pr-1"
    clearable
    role="combobox"
    aria-expanded={open}
    aria-haspopup="listbox"
    aria-controls="suggestions"
    aria-activedescendant={selectedSuggestionId}
    aria-autocomplete="list"
    aria-label="Search query"
    on:clear={() => {
      clear();
      clearSearch();
    }}
    on:enter={search}
    on:backspace={onBackspace}
    on:blur={closeSuggestions}
    bind:value={query}
    bind:elem={input}
  >
    <div
      class="flex-shrink-0 flex gap-2 px-0.5"
      slot="leading"
      bind:this={leading}
    ></div>

    <div class="flex items-center" slot="trailing">
      <button
        class="p-2 bg-primary/20 hover:bg-primary rounded-md text-sm text-primary-foreground"
        on:click={search}
      >
        <SearchIcon />
      </button>
    </div>
  </Input>

  {#if open}
    <div
      class="absolute top-full w-full mt-0.5 bg-white border border-zinc-400/75 rounded-lg shadow-sm shadow-primary/30 text-sm overflow-hidden"
    >
      {#if suggestions.tags.length}
        <div class="flex">
          <span class="w-1/5 p-2 bg-zinc-100 text-zinc-500 text-sm">Tags</span>
          <ul
            id="suggestions"
            class="w-full border-l border-zinc-300"
            role="listbox"
          >
            {#each suggestions.tags as tag}
              {@const tag_id = `${tag.tag_field_name}_${tag.value}`}
              <li
                id={tag_id}
                class="p-2 focus-within:bg-blue-100"
                role="option"
                aria-selected={tag_id === selectedSuggestionId}
              >
                <button
                  class="flex items-center focus:outline-none"
                  on:click={() => selectTagFilter(tag)}
                >
                  <span class="text-muted-foreground">
                    {tag.tag_field_name}
                  </span>
                  <CaretRight class="px-0.5 text-xs text-muted-foreground" />
                  <span class="font-medium">{tag.value}</span>
                </button>
              </li>
            {/each}
          </ul>
        </div>
      {/if}
    </div>
  {/if}
</div>
