<script context="module" lang="ts">
  import { type ComponentType, type ComponentProps } from "svelte";
  import type { FlyParams } from "svelte/transition";

  export type CustomComponent<Component extends ComponentType = ComponentType> =
    {
      src: ComponentType;
      props: ComponentProps<InstanceType<Component>>;
      sendIdTo: string;
    };

  export type OnPopCallback = (id: number, details: Object) => void;

  export type ToastOptions = {
    id: number;
    target: string;
    msg: string;
    duration: number;
    initial: number;
    next: number;
    pausable: boolean;
    dismissable: boolean;
    reversed: boolean;
    progress: boolean;
    icon: boolean;
    intro: FlyParams;
    theme: Record<string, string | number>;
    classes: string[];
    onpop?: OnPopCallback;
    component?: CustomComponent;
    style?: "dark" | "danger" | "warning" | "info" | "success";
  };
</script>

<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { tweened } from "svelte/motion";
  import { linear } from "svelte/easing";

  import { toast } from "./stores";

  import XIcon from "~icons/ph/x-bold";
  import DangerIcon from "~icons/ph/warning-circle-fill";
  import WarningIcon from "~icons/ph/warning-fill";
  import InfoIcon from "~icons/ph/info-fill";
  import SuccessIcon from "~icons/ph/check-circle-fill";

  export let item: ToastOptions;

  let next = item.initial;
  let prev = next;
  let paused = false;
  let cprops = {};
  let unlisten: () => void;
  let event: MouseEvent | KeyboardEvent;

  const progress = tweened(item.initial, {
    duration: item.duration,
    easing: linear,
  });

  function close(_event?: MouseEvent | KeyboardEvent) {
    if (_event) event = _event;
    toast.pop(item.id);
  }

  function autoclose() {
    if ($progress === 1 || $progress === 0) close();
  }

  function pause() {
    if (!paused && $progress !== next) {
      progress.set($progress, { duration: 0 });
      paused = true;
    }
  }

  function resume() {
    if (paused) {
      const d = item.duration;
      const duration = d - d * (($progress - prev) / (next - prev));
      progress.set(next, { duration }).then(autoclose);
      paused = false;
    }
  }

  function check(prop: unknown, kind = "undefined") {
    return typeof prop === kind;
  }

  function listen(d = document) {
    if (check(d.hidden)) return;
    const handler = () => (d.hidden ? pause() : resume());
    const name = "visibilitychange";
    d.addEventListener(name, handler);
    unlisten = () => d.removeEventListener(name, handler);
    handler();
  }

  $: if (next !== item.next) {
    next = item.next;
    prev = $progress;
    paused = false;
    progress.set(next).then(autoclose);
  }

  $: if (item.component) {
    const { props = {}, sendIdTo } = item.component;
    cprops = { ...props, ...(sendIdTo && { [sendIdTo]: item.id }) };
  }

  onMount(listen);

  onDestroy(() => {
    item.onpop && item.onpop(item.id, { event });
    unlisten && unlisten();
  });
</script>

<div
  role="status"
  class="toast"
  class:dark={item.style === "dark"}
  class:warning={item.style === "warning"}
  class:danger={item.style === "danger"}
  class:success={item.style === "success"}
  on:mouseenter={() => {
    if (item.pausable) pause();
  }}
  on:mouseleave={resume}
>
  <div class="flex items-center gap-3 py-2 px-3">
    {#if item.icon}
      <div class="flex-shrink-0 w-6 h-6">
        {#if item.style === "danger"}
          <DangerIcon class="w-full h-full" />
        {:else if item.style === "warning"}
          <WarningIcon class="w-full h-full" />
        {:else if item.style === "info"}
          <InfoIcon class="w-full h-full" />
        {:else if item.style === "success"}
          <SuccessIcon class="w-full h-full" />
        {:else}
          <InfoIcon class="w-full h-full" />
        {/if}
      </div>
    {/if}

    <div class="msg" class:pointer-events-none={item.component}>
      {#if item.component}
        <svelte:component this={item.component.src} {...cprops} />
      {:else}
        {@html item.msg}
      {/if}
    </div>

    {#if item.dismissable}
      <div class="flex-shrink-0 ms-auto divider"></div>

      <button
        class="flex-shrink-0 w-4 h-4 pointer-events-auto"
        on:click={(ev) => close(ev)}
        on:keydown={(ev) => {
          if (ev instanceof KeyboardEvent && ["Enter", " "].includes(ev.key))
            close(ev);
        }}
      >
        <XIcon class="w-full h-full" />
      </button>
    {/if}
  </div>

  {#if item.progress}
    <progress class="_toastBar" value={$progress} />
  {/if}
</div>

<style>
  .toast {
    @apply w-80 h-auto mb-2 bg-gradient-to-t from-zinc-200 to-zinc-100 border border-zinc-400/50 rounded-sm text-sm text-zinc-700;

    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.35),
      inset 0px 1px 0px rgba(255, 255, 255, 0.75);
  }

  .toast.dark {
    @apply from-zinc-700 to-zinc-600 text-zinc-50;

    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.35),
      inset 0px 1px 0px rgba(255, 255, 255, 0.2);
  }

  .toast.warning {
    @apply from-amber-600 to-amber-500 border-amber-700 text-amber-50;

    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.35),
      inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  }

  .toast.danger {
    @apply from-red-700 to-red-600 border-red-900 text-red-50;

    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.35),
      inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  }

  .toast.success {
    @apply from-green-700 to-green-600 border-green-900 text-green-50;

    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.35),
      inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  }

  .toast .msg :global(a) {
    pointer-events: auto;
  }

  .toast .divider {
    @apply self-stretch w-[1px] bg-zinc-50;
  }

  .toast.dark .divider {
    @apply bg-zinc-400/25;
  }

  .toast.warning .divider {
    @apply bg-amber-700/50;
  }

  .toast.danger .divider {
    @apply bg-red-900/60;
  }

  .toast.success .divider {
    @apply bg-green-900/25;
  }
</style>
