<script lang="ts">
  import { DropdownMenu as DropdownMenuPrimitive } from "bits-ui";
  import { cn } from "$lib/utils.js";

  type $$Props = DropdownMenuPrimitive.ContentProps;

  let className: $$Props["class"] = undefined;
  export let sideOffset: $$Props["sideOffset"] = 4;
  export { className as class };
</script>

<DropdownMenuPrimitive.Content
  {sideOffset}
  class={cn(
    "z-50 min-w-32 max-w-64 p-1 bg-popover border border-zinc-400/60 rounded-md focus:outline-none shadow-lg shadow-black/30 text-popover-foreground",
    className,
  )}
  {...$$restProps}
  on:keydown
>
  <slot />
</DropdownMenuPrimitive.Content>
