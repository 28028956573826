// JsFromRoutes CacheKey 4259bd62a388330a6fc792e3f56b6e93
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  download: /* #__PURE__ */ definePathHelper('get', '/publications/:encoded_id/download'),
  index: /* #__PURE__ */ definePathHelper('get', '/publications'),
  create: /* #__PURE__ */ definePathHelper('post', '/publications'),
  new: /* #__PURE__ */ definePathHelper('get', '/publications/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/publications/:encoded_id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/publications/:encoded_id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/publications/:encoded_id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/publications/:encoded_id'),
}
