<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";

  import { cn } from "$lib/utils.js";

  type $$Props = HTMLAttributes<HTMLTableRowElement> & {
    "data-state"?: unknown;
  };

  let rowClass = cn(
    "border-b transition-colors data-[state=selected]:bg-muted",
    $$props.class,
  );
</script>

<tr class={rowClass} {...$$restProps} on:click on:keydown>
  <slot />
</tr>
