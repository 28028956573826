<script lang="ts">
  import { type PaginationData } from "$lib/backend";

  import StandardPage from "$components/admin/StandardPage.svelte";
  import ListView, {
    type Column,
    type RowActions,
  } from "$components/views/ListView.svelte";

  import * as Breadcrumb from "$components/ui/breadcrumb";

  import { datetime } from "$lib/utils";

  export let publications: Schema.Publication[];
  export let pagination: PaginationData;

  const columns: Column<Schema.Publication>[] = [
    {
      name: "creator",
      label: "Creator",
      format: (creator?: Schema.User) => creator?.full_name ?? "",
    },
    {
      name: "publishable_type",
      label: "Content",
      format: (type?: string, record?: Schema.Publication) => {
        switch (type) {
          case "Asset":
            return `Asset #${record?.publishable_id}`;
          case "AssetPack":
            return `Asset Pack #${record?.publishable_id}`;
          default:
            return type ?? "";
        }
      },
    },
    {
      name: "email_recipients",
      label: "E-mail",
      format: (recipients?: string[]) => {
        if (recipients?.length) {
          return `<b>Yes</b>, to ${recipients.length} ${recipients.length > 1 ? "recipients" : "recipient"}`;
        } else {
          return "No";
        }
      },
    },
    {
      name: "created_at",
      label: "Creation Date",
      format: (createdAt: string) =>
        datetime.format(createdAt, { dateStyle: "medium", timeStyle: "short" }),
    },
  ];

  const rowActions: RowActions = {};
</script>

<StandardPage hasBackBtn>
  <svelte:fragment slot="breadcrumb">
    <Breadcrumb.Root>
      <Breadcrumb.List>
        <Breadcrumb.Item>Asset Management</Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page>Publications</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  </svelte:fragment>

  <ListView
    title="Publications"
    rows={publications}
    {columns}
    {rowActions}
    {pagination}
    class="mt-4"
  >
    <p slot="empty-content">There are no publications yet.</p>
  </ListView>
</StandardPage>
