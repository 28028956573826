<script context="module" lang="ts">
  import ConfirmationDialog from "./ConfirmationDialog.svelte";

  export function triggerConfirmation(
    props: ComponentProps<ConfirmationDialog>,
  ): Promise<{ confirmed: boolean }> {
    return new Promise((resolve, _) => {
      props.isOpen ??= true;

      const dialog = new ConfirmationDialog({
        target: document.body,
        props,
      });

      dialog.$on("cancel", () => {
        resolve({ confirmed: false });
        dialog.$destroy();
      });

      dialog.$on("confirm", () => {
        resolve({ confirmed: true });
        dialog.$destroy();
      });
    });
  }
</script>

<script lang="ts">
  import { createEventDispatcher, onMount, type ComponentProps } from "svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";

  import XIcon from "~icons/ph/x";
  import DangerIcon from "~icons/ph/warning-fill";

  export let title: string | undefined = undefined;
  export let description: string;
  export let cancelBtnLabel: string = "Cancel";
  export let confirmBtnLabel: string = "Continue";
  export let isOpen: boolean = false;
  export let cancellable: boolean = true;
  export let hasBackdrop: boolean = true;
  export let type: "default" | "danger" = "default";
  export let hasIcon: boolean = false;

  const dispatch = createEventDispatcher<{
    cancel: undefined;
    confirm: undefined;
  }>();

  let open: boolean = false;

  onMount(() => {
    open = isOpen;
  });

  function onOpenChange(open: boolean) {
    if (!open) onCancelClick();
  }

  function onCancelClick() {
    open = false;
    dispatch("cancel");
  }

  function onConfirmClick() {
    open = false;
    dispatch("confirm");
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Portal>
    {#if hasBackdrop}
      <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />
    {/if}

    <Dialog.Content class="block p-0 bg-zinc-100 border-none">
      {#if title}
        <Dialog.Header class="flex-row space-y-0 bg-primary rounded-t-lg">
          <Dialog.Title class="p-4 text-zinc-50 font-semibold">
            {title}
          </Dialog.Title>
          <Dialog.Close class="ms-auto">
            <XIcon />
          </Dialog.Close>
        </Dialog.Header>
      {/if}

      <div class="flex items-center py-6 px-4">
        {#if hasIcon}
          {#if $$slots.icon}
            <slot name="icon" />
          {:else if type === "danger"}
            <DangerIcon class="w-8 h-8 me-4 text-red-600" />
          {/if}
        {/if}

        <div>
          {@html description}
        </div>
      </div>

      <Dialog.Footer
        class="p-2 bg-zinc-200 border-t border-zinc-300 rounded-b-lg"
      >
        {#if cancellable}
          <Button
            variant="secondary"
            class="hover:bg-zinc-300"
            on:click={onCancelClick}
          >
            {cancelBtnLabel}
          </Button>
        {/if}

        <Button
          class={type === "danger" ? "btn-danger" : undefined}
          on:click={onConfirmClick}
        >
          {confirmBtnLabel}
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
