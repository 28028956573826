<script lang="ts">
  import { inertia } from "@inertiajs/svelte";

  import API from "$api";

  import Link from "$components/Link.svelte";

  import ArrowLeftIcon from "~icons/ph/arrow-left";
  import TagFieldIcon from "~icons/ph/hash";
  import TagValueIcon from "~icons/ph/tag";
  import DownloadIcon from "~icons/ph/box-arrow-down";
  import ShareIcon from "~icons/ph/share-network";
  import UserIcon from "~icons/ph/user";
  import TeamIcon from "~icons/ph/users";
  import EmailIcon from "~icons/ph/envelope-simple";
  import BackgroundJobIcon from "~icons/ph/gear";
  import ExternalLinkIcon from "~icons/ph/arrow-square-out";
</script>

<div class="relative flex h-full overflow-y-auto">
  <aside
    class="sticky top-0 left-0 flex-shrink-0 min-w-64 bg-zinc-100 border-r border-zinc-300"
  >
    <div class="pt-6 px-6">
      <a
        href={API.home.index.path()}
        class="group flex items-center px-2 font-medium text-zinc-700 hover:text-zinc-900"
        use:inertia
      >
        <ArrowLeftIcon class="group-hover:-ms-4 me-1 pt-0.5 transition-all" />
        Back to app
      </a>
    </div>

    <div class="pt-6 px-6">
      <span class="px-2 text-sm font-semibold text-zinc-600"
        >Asset Management</span
      >
      <ul class="pt-2 menu">
        <li>
          <Link
            href={API.adminDownloads.index.path()}
            class="flex items-center gap-3"
          >
            <DownloadIcon class="mt-0.5" />
            Downloads
          </Link>
        </li>
        <li>
          <Link
            href={API.adminPublications.index.path()}
            class="flex items-center gap-3"
          >
            <ShareIcon class="mt-0.5" />
            Publications
          </Link>
        </li>
      </ul>
    </div>

    <div class="pt-6 px-6">
      <span class="px-2 text-sm font-semibold text-zinc-600">
        Tag Management
      </span>
      <ul class="pt-2 menu">
        <li>
          <Link
            href={API.adminTagFields.index.path()}
            class="flex items-center gap-3"
          >
            <TagFieldIcon class="mt-0.5" />
            Tag Fields
          </Link>
        </li>
        <li>
          <Link
            href={API.adminTagValues.index.path()}
            class="flex items-center gap-3"
          >
            <TagValueIcon class="mt-0.5" />
            Predefined Values
          </Link>
        </li>
      </ul>
    </div>

    <div class="pt-6 px-6">
      <span class="px-2 text-sm font-semibold text-zinc-600">
        User Management
      </span>
      <ul class="pt-2 menu">
        <li>
          <Link
            href={API.adminUsers.index.path()}
            class="flex items-center gap-3"
          >
            <UserIcon class="mt-0.5" />
            Users
          </Link>
        </li>
        <li>
          <Link
            href={API.adminTeams.index.path()}
            class="flex items-center gap-3"
          >
            <TeamIcon class="mt-0.5" />
            Teams
          </Link>
        </li>
      </ul>
    </div>

    <div class="pt-6 px-6">
      <span class="px-2 text-sm font-semibold text-zinc-600">System</span>
      <ul class="pt-2 menu">
        <li>
          <a
            href="/admin/background_jobs"
            class="flex items-center gap-3"
            target="_blank"
          >
            <EmailIcon class="mt-0.5" />
            E-mails
          </a>
        </li>
        <li>
          <a
            href="/admin/background_jobs"
            class="flex items-center gap-3"
            target="_blank"
          >
            <BackgroundJobIcon class="mt-0.5" />
            Background Jobs
            <ExternalLinkIcon class="mt-0.5 ms-auto" />
          </a>
        </li>
      </ul>
    </div>
  </aside>

  <div class="flex-grow">
    <slot />
  </div>
</div>

<style>
  .menu :global(a) {
    @apply py-2 px-2 border border-transparent rounded-lg text-zinc-700 transition-all;
  }

  .menu :global(a:hover) {
    @apply bg-zinc-200 border-zinc-300 text-zinc-900;
  }

  .menu :global(a.active) {
    @apply bg-primary/70 border-zinc-800 shadow-inner shadow-zinc-800 font-medium text-zinc-50;
  }
</style>
