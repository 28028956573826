<script lang="ts">
  import { setContext } from "svelte";
  import type { Writable } from "svelte/store";
  import { slide } from "svelte/transition";
  import type { InertiaForm } from "@inertiajs/svelte";

  import TAGGABLE_TYPES from "$data/taggable_types.json";

  import Input from "$components/forms/Input.svelte";
  import Checkbox from "$components/forms/Checkbox.svelte";

  import { Label } from "$components/ui/label";
  import Select from "$components/forms/Select.svelte";
  import SelectItem from "$components/forms/SelectItem.svelte";

  export let form: Writable<InertiaForm<NewRecord<Schema.TagField>>>;

  let taggableTypes = TAGGABLE_TYPES.map((t) => ({ name: t }));
  let freeEntry: boolean = false;
  let isNew: boolean = $form.id === undefined;

  let isEnabled: boolean = $form.status === "enabled";
  $: if (isEnabled === true) {
    form.update((curr) => ({ ...curr, status: "enabled" }));
  } else if (isEnabled === false) {
    form.update((curr) => ({ ...curr, status: "disabled" }));
  }

  setContext("form", form);
</script>

<div class="grid grid-cols-2 gap-4 w-full">
  <div class="space-y-1">
    <Label class="mb-1" required>Name</Label>
    <Input name="name" disabled={!isNew} />
  </div>

  <div class="space-y-1">
    <Label class="mb-1" required>Applicable to</Label>
    {#if isNew}
      <Select
        name="taggable_type"
        items={taggableTypes}
        labelKey="name"
        valueKey="name"
        placeholder="Select a type"
      >
        {#each TAGGABLE_TYPES as taggableType}
          <SelectItem value={taggableType}>{taggableType}</SelectItem>
        {/each}
      </Select>
    {:else}
      <Input name="taggable_type" disabled />
    {/if}
  </div>

  <Checkbox name="enabled" label="Enabled" bind:checked={isEnabled} />

  <Checkbox
    name="required"
    label="Required tag"
    hint="Ask user to enter a value for this tag on import."
  />

  <Checkbox
    name="free_entry"
    label="Free entry"
    hint="Allow user to write any tag value."
    disabled={!isNew}
    bind:checked={freeEntry}
  />

  <Checkbox
    name="multi_values_allowed"
    label="Multiple values"
    disabled={!isNew}
    hint="Allow user to apply this tag more than once."
  />

  {#if freeEntry}
    <div class="space-y-1" transition:slide>
      <Label>
        Format
        <span class="text-zinc-500 font-light text-xs"> (optional) </span>
      </Label>
      <Input name="format" hint="Validate the value given to the tag." />
    </div>
  {/if}
</div>
